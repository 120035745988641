import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"

const Terms = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout
      location={location}
      title={siteTitle}
      invert
      className="bg-blue-500"
    >
      <Seo title="Terms and Conditions" />
      <Container>
        <h1>Terms and Conditions</h1>
        <p>
          <strong>1. Introduction</strong>
        </p>

        <p>
          These Website Terms and Conditions (these &ldquo;Terms&rdquo; or these
          &ldquo;Website Terms and Conditions&rdquo;) contained herein on this
          webpage, shall govern your use of this website, including all pages
          within this website (collectively referred to herein below as this
          &ldquo;Website&rdquo;). These Terms apply in full force and effect to
          your use of this Website and by using this Website, you expressly
          accept all terms and conditions contained herein in full. You must not
          use this Website, if you have any objection to any of these Website
          Terms and Conditions.&nbsp;
        </p>

        <p>
          This Website is not for use by any minors (defined as those who are
          not at least 18 years of age), and you must not use this Website if
          you a minor.&nbsp;
        </p>

        <p>
          <strong>2. Intellectual Property Rights</strong>
        </p>

        <p>
          Other than content you own, which you may have opted to include on
          this Website, under these Terms, PsychicReadingServices.com and/or its
          licensors own all rights to the intellectual property and material
          contained in this Website, and all such rights are reserved.&nbsp;
        </p>

        <p>
          You are granted a limited license only, subject to the restrictions
          provided in these Terms, for purposes of viewing the material
          contained on this Website.
        </p>

        <p>
          <strong>3. Restrictions</strong>
        </p>
        <ul class="bullet-list">
          <li>
            You are expressly and emphatically restricted from all of the
            following:
          </li>

          <li>publishing any Website material in any media;</li>

          <li>
            selling, sublicensing and/or otherwise commercializing any Website
            material;
          </li>

          <li>publicly performing and/or showing any Website material;</li>

          <li>
            using this Website in any way that is, or may be, damaging to this
            Website;
          </li>

          <li>
            using this Website in any way that impacts user access to this
            Website;
          </li>

          <li>
            using this Website contrary to applicable laws and regulations, or
            in a way that causes, or may cause, harm to the Website, or to any
            person or business entity;
          </li>

          <li>
            engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website, or while using
            this Website;
          </li>

          <li>using this Website to engage in any advertising or marketing;</li>
        </ul>

        <p>
          Certain areas of this Website are restricted from access by you and
          PsychicReadingServices.com may further restrict access by you to any
          areas of this Website, at any time, in its sole and absolute
          discretion.  Any user ID and password you may have for this Website
          are confidential and you must maintain confidentiality of such
          information.&nbsp;
        </p>

        <p>
          <strong>4. No warranties</strong>
        </p>

        <p>
          This Website is provided &ldquo;as is,&rdquo; with all faults, and
          PsychicReadingServices.com makes no express or implied representations
          or warranties, of any kind related to this Website or the materials
          contained on this Website. Additionally, nothing contained on this
          Website shall be construed as providing consult or advice to
          you.&nbsp;
        </p>

        <p>
          <strong>5. Limitation of liability</strong>
        </p>

        <p>
          In no event shall PsychicReadingServices.com, nor any of its officers,
          directors and employees, be liable to you for anything arising out of
          or in any way connected with your use of this Website, whether such
          liability is under contract, tort or otherwise, and
          PsychicReadingServices.com, including its officers, directors and
          employees shall not be liable for any indirect, consequential or
          special liability arising out of or in any way related to your use of
          this Website.&nbsp;
        </p>

        <p>
          <strong>6. Indemnification</strong>
        </p>

        <p>
          You hereby indemnify to the fullest extent PsychicReadingServices.com
          from and against any and all liabilities, costs, demands, causes of
          action, damages and expenses (including reasonable attorney&rsquo;s
          fees) arising out of or in any way related to your breach of any of
          the provisions of these Terms.&nbsp;
        </p>

        <p>
          <strong>7. Severability</strong>
        </p>

        <p>
          If any provision of these Terms is found to be unenforceable or
          invalid under any applicable law, such unenforceability or invalidity
          shall not render these Terms unenforceable or invalid as a whole, and
          such provisions shall be deleted without affecting the remaining
          provisions herein.&nbsp;
        </p>

        <p>
          <strong>8. Variation of Terms</strong>
        </p>

        <p>
          PsychicReadingServices.com is permitted to revise these Terms at any
          time as it sees fit, and by using this Website you are expected to
          review such Terms on a regular basis to ensure you understand all
          terms and conditions governing use of this Website.&nbsp;
        </p>

        <p>
          <strong>9. Assignment</strong>
        </p>

        <p>
          PsychicReadingServices.com shall be permitted to assign, transfer, and
          subcontract its rights and/or obligations under these Terms without
          any notification or consent required. However, you shall not be
          permitted to assign, transfer, or subcontract any of your rights
          and/or obligations under these Terms.&nbsp;
        </p>

        <p>
          <strong>10. Entire Agreement</strong>
        </p>

        <p>
          These Terms, including any legal notices and disclaimers contained on
          this Website, constitute the entire agreement between
          PsychicReadingServices.com and you in relation to your use of this
          Website, and supersede all prior agreements and understandings with
          respect to the same.&nbsp;
        </p>

        <p>
          <strong>11. Governing Law &amp; Jurisdiction</strong>
        </p>

        <p>
          These Terms will be governed by and construed in accordance with the
          laws of the Commonwealth of Pennsylvania or the U.S. Federal
          Government (to the extent applicable), and you submit to the exclusive
          jurisdiction of the state and federal courts located in the
          Philadelphia, Pennsylvania, United States of America for the
          resolution of any disputes.
        </p>
      </Container>
    </Layout>
  )
}

export default Terms

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
